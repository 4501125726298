<template>
    <bouncing-burger-overlay :value="true" :message="$t('message.checkingPayment')" />
</template>

<script>
    import BouncingBurgerOverlay from "../UI/BouncingBurgerOverlay";
    import APIOrder from "../../api/APIOrder";

    export default {
        name: "PaymentInflight",
        data() {
            return {
                retries: 3,
                isOrderPaid: false,
                isPaymentRejected: false
            }
        },
        mounted() {
            this.checkIfPaid();
        },
        components: {BouncingBurgerOverlay},
        methods: {
            checkIfPaid() {
                const me = this;
                APIOrder.getOrderStatus(this, this.sessOrderId)
                    .then((response) => {
                        if (response.data.data.order_status_id === APIOrder.Constants.OrderStatus.COMPLETE) {
                            me.isOrderPaid = true;
                        } else if (response.data.data.order_status_id === APIOrder.Constants.OrderStatus.DENIED) {
                            me.isPaymentRejected = true;
                        }
                    })
                    .finally(() => {
                        if (me.isOrderPaid) {
                            me.isPaid();
                        } else if (me.isPaymentRejected) {
                            me.orderIsNotPaid();
                        } else {
                            if (--me.retries > 0) {
                                window.setTimeout(me.checkIfPaid, 1500);
                            } else {
                                me.orderIsNotPaid();
                            }
                        }
                    })
            },
            isPaid() {
                this.$store.commit('session/orderProgress', APIOrder.Constants.OrderProgress.CHECKOUT);
                this.$store.commit('session/isPaymentRejected', false);
                this.$router.replace({name: 'order-complete'});
            },
            orderIsNotPaid() {
                if (this.isPaymentRejected) {
                    this.$store.commit('session/isPaymentRejected', true);
                }

                if (APIOrder.isNothingToAskForAtCheckout(this.$store)) {
                    this.$store.commit('session/orderProgress', APIOrder.Constants.OrderProgress.MENU);
                    this.$router.replace({name: 'order'});
                } else {
                    this.$store.commit('session/orderProgress', APIOrder.Constants.OrderProgress.CHECKOUT);
                    this.$router.replace({name: 'checkout'});
                }
            }
        },
        computed: {
            sessOrderId() {
                return this.$store.getters['session/orderId'];
            },
        }
    }
</script>

<style scoped>

</style>

